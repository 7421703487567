import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby"
import HayPromoHayMundial from "../components/hayPromoHayMundial/MainMundial"
import Footer from "../components/hayPromoHayMundial/Footer"
import SEO from "../components/seo"

function ElMundial({ data }) {
  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (!session && !local)
      navigate("/agegate", { state: { newPath: "/localesadheridos" } })
  }, [])

  const puntosDeVenta = data.allLocalesAdheridos.nodes;
  const provincePoints = data.allProvince.nodes;
  const cityPoints = data.allCity.nodes;

  let provinces = provincePoints.map((point) => {
    return point.name.toUpperCase();
  });
  let localidades = cityPoints;

  return (
    <>
      <SEO title={"Quilmes"} description={""} />
      <HayPromoHayMundial
        puntosDeVenta={puntosDeVenta.map(puntoDeVenta => {
          return {
            domicilio: puntoDeVenta.domicilio,
            localidad: puntoDeVenta.localidad.toUpperCase(),
            provincia: puntoDeVenta.provincia.toUpperCase(),
            lat: Number(puntoDeVenta.lat),
            lng: Number(puntoDeVenta.lng),
            codPdv: puntoDeVenta.id,
          }
        })}
        localidades={localidades}
        provinces={provinces}
        provincePoints={provincePoints.map((province) => {
          return {
            lat: Number(province.lat),
            lng: Number(province.lng),
            provincia: province.name.toUpperCase(),
          };
        })}
      />
      <Footer />
    </>
  )
}

export default ElMundial;

export const data = graphql`
  query LocalAdheridos {
    allLocalesAdheridos {
      nodes {
        id
        codPdv
        domicilio
        localidad
        provincia
        lat
        lng
      }
    }
    allProvince {
      nodes {
        id
        name
        lat
        lng
      }
    }
    allCity {
      nodes {
        id
        name
        lat
        lng
        province
      }
    }
  }
`;
