import React from "react";
import styles from "../../styles/hayPromoHayMundial/pdvCard.module.css";

export default function CardPuntoDeVenta({ pdv, setZoom, setPosition }) {
console.log("pase por CardPDV")

    const handleSelectPdv = ()=>{
        setZoom(16)
        setPosition({lat:pdv.lat, lng:pdv.lng})
        window.dataLayer.push({
          event: "GAEvent",
          event_category: "Search",
          event_action: pdv.provincia,
          event_label: pdv.localidad,
          interaction: "True",
          component_name: pdv.domicilio,
          element_text: pdv.localidad,
        });
    }

    return (
            <div className={styles.cardContainer} onClick={handleSelectPdv} >
                <h1>{pdv.domicilio}</h1>
                <p>{pdv.localidad}, {pdv.provincia}. </p>
            </div>
    )
}
