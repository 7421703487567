import React, { useMemo, useState } from "react"
import { Link } from "gatsby"
import styles from "../../styles/hayPromoHayMundial/main.module.css"
import logoQ from "../../images/logos/quilmesLogo.svg"
import clearIcon from "../../images/hayPromoHayMundial/clear.svg"
import searchIcon from "../../images/hayPromoHayMundial/search.svg"
import loadable from "@loadable/component"
import { greatPlaceStyle, mapStyles } from "./MapStyle"
import Pointer from "../hayPromoHayMundial/Pointer"
import SliderPuntoDeVenta from "../hayPromoHayMundial/SliderPdv"

const GoogleMapReact = loadable(() => import("google-map-react"))

export default function HayPromoHayMundial({
  puntosDeVenta,
  provinces,
  localidades,
  provincePoints
}) {
  const [searchInput, setSearchInput] = useState("")
  const [provinceInput, setProvinceInput] = useState("")
  const [searchOptions, setSearchOptions] = useState([])
  const [provinceOptions, setProvinceOptions] = useState([])
  const [bounds, setBounds] = useState(null)
  const [selectedProvince, setSelectedProvince] = useState(null)
  const [selectedLocalidad, setSelectedLocalidad] = useState(null)
  const [provincePointers, setProvincePointers] = useState([])

  const isPointerInBounds = pointer => {
    if (!bounds) return false

    return (
      pointer.lat < bounds.ne.lat &&
      pointer.lat > bounds.sw.lat &&
      pointer.lng < bounds.ne.lng &&
      pointer.lng > bounds.sw.lng
    )
  }

  const provincePdvs = provincePoints

  const [position, setPosition] = useState({
    lat: -34.99729,
    lng: -64.82106,
  })
  const [zoom, setZoom] = useState(4)
  const [pdvs, setPdvs] = useState([])
  const [visiblePointers, setVisiblePointers] = useState(
    puntosDeVenta.filter(puntoDeVenta => {
      return puntoDeVenta.lat && puntoDeVenta.lng
    })
  )

  const handleChange = value => {
    setSearchInput(value)
    if (value.length >= 2) {
      let filtradas = localidades.filter(localidad => {
        if (
          localidad.name.toLowerCase().includes(value.toLowerCase()) &&
          localidad.province === provinceInput
        )
          return true
      })
      setSearchOptions(filtradas)
    } else if (value.length === 1) {
      setSearchOptions(
        localidades.filter(localidad => localidad.province === provinceInput)
      )
    } else {
      setSearchOptions([])
    }
  }

  const handleProvinceChange = value => {
    setProvinceInput(value)
    if (value.length >= 2) {
      let filtradas = provinces.filter(province => {
        if (province.toLowerCase().includes(value.toLowerCase())) return true
      })
      setProvinceOptions(filtradas)
    } else if (value.length === 1) {
      setProvinceOptions(provinces)
    } else {
      setSearchOptions([])
    }
  }

  const clearInput = () => {
    setSearchInput("")
    setProvinceInput("")
    setSelectedProvince(null)
    setSelectedLocalidad(null)
    setSearchOptions([])
    setProvinceOptions([])
    setProvincePointers([])
    setPdvs([])
    setVisiblePointers(puntosDeVenta)
    setPosition({ lat: -34.99729, lng: -64.82106 })
    setZoom(4)
  }

  const handleBtnsDropdown = localidad => {
    let pdvFiltrados = provincePointers.filter(puntoDeVenta => {
      if (puntoDeVenta.localidad === localidad.name) return true
    })
    setPdvs(pdvFiltrados)
    setSearchInput(localidad.name)
    setSearchOptions([])
    setVisiblePointers(pdvFiltrados)
    setSelectedLocalidad(localidad.name)
    setPosition({
      lat: Number(localidad.lat),
      lng: Number(localidad.lng),
    })
    setZoom(14)

    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Search",
      event_action: "Query",
      event_label: localidad.name,
      interaction: "True",
      component_name: "boton_localidad",
      element_text: localidad.name,
    })
  }

  const handleBtnsDropdownProvince = province => {
    let pdvFiltrados = visiblePointers.filter(puntoDeVenta => {
      if (puntoDeVenta.provincia === province) return true
    })
    setPdvs(pdvFiltrados)
    setProvinceInput(province)
    setProvinceOptions([])
    setProvincePointers(pdvFiltrados)
    setSelectedProvince(province)
    const positionPdv = provincePdvs.find(pdv => pdv.provincia === province)
    setPosition({
      lat: Number(positionPdv.lat),
      lng: Number(positionPdv.lng),
    })
    setZoom(9)

    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Search",
      event_action: "Query",
      event_label: province,
      interaction: "True",
      component_name: "boton_search",
      element_text: province,
    })
  }

  const defaultProps = {
    center: {
      lat: -34.99729,
      lng: -64.82106,
    },
    zoom: 8,
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerMain}>
        <Link to={"/"}>
          <img className={styles.quilmesIcon} src={logoQ} />
        </Link>
      </div>
      <div className={styles.topContainer}>
        <h2 className={styles.title}>ELEGÍ TU LOCAL ADHERIDO MÁS CERCANO</h2>
        <div className={styles.searchContainer}>
          <div className={styles.search}>
            <div className={styles.inputsContainer}>
              <div className={styles.searchBar}>
                <img className={styles.searchIcon} src={searchIcon} />
                <input
                  type="text"
                  value={provinceInput}
                  placeholder="Buscar provincia"
                  onChange={e => handleProvinceChange(e.target.value)}
                  className={styles.searchInput}
                />
                <div className={styles.clearContainer}>
                  {provinceInput.length ? (
                    <img
                      className={styles.clearIcon}
                      src={clearIcon}
                      onClick={clearInput}
                    />
                  ) : null}
                </div>
                <div className={styles.dropdownResults}>
                  {provinceOptions.map((province, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          searchOptions.length !== index + 1
                            ? styles.searchOptions
                            : styles.searchOptionsLast
                        }
                        onClick={() => handleBtnsDropdownProvince(province)}
                      >
                        <p> {province}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
              {provinceInput && (
                <div className={styles.searchBar}>
                  <img className={styles.searchIcon} src={searchIcon} />
                  <input
                    type="text"
                    value={searchInput}
                    placeholder="Buscar localidad"
                    onChange={e => handleChange(e.target.value)}
                    className={styles.searchInput}
                  />
                  <div className={styles.clearContainer}>
                    {searchInput.length ? (
                      <img
                        className={styles.clearIcon}
                        src={clearIcon}
                        onClick={clearInput}
                      />
                    ) : null}
                  </div>
                  <div className={styles.dropdownResults}>
                    {searchOptions.map((localidad, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            searchOptions.length !== index + 1
                              ? styles.searchOptions
                              : styles.searchOptionsLast
                          }
                          onClick={() => handleBtnsDropdown(localidad)}
                        >
                          <p> {localidad.name}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>

            <div className={styles.rightInfo}>
              {pdvs.length > 1 && (
                <div className={styles.infoResults}>
                  <p>{pdvs.length} puntos de venta encontrados</p>
                </div>
              )}
              {pdvs.length === 1 && (
                <div className={styles.infoResults}>
                  <p>{pdvs.length} punto de venta encontrado</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <p className={styles.text}>
          {!(selectedProvince && !selectedLocalidad)
            ? "Seleccioná tu provincia y localidad para encontrar el punto de venta adherido más cercano."
            : "¡Hace zoom en tu localidad y encontra el punto de venta adherido más cercano!"}
        </p>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.pdvsContainer}>
          {selectedProvince && selectedLocalidad && (
            <SliderPuntoDeVenta
              pdvs={pdvs}
              setPdvs={setPdvs}
              setZoom={setZoom}
              setPosition={setPosition}
              // handleBtnsDropdown={handleBtnsDropdown}
            />
          )}
        </div>
        <div className={styles.googleMapContainer}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBARPZHLqZGB5Ws-P98HsHLoP9KxWOV13k",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            style={greatPlaceStyle}
            center={position}
            zoom={zoom}
            onChange={({ center, zoom, bounds }) => {
              setBounds({
                ne: bounds.ne,
                sw: bounds.sw,
              })
              setZoom(zoom)
            }}
            options={{
              gestureHandling: "greedy",
              styles: mapStyles,
            }}
          >
            {(zoom < 11
              ? provincePdvs
              : selectedProvince && selectedLocalidad
              ? visiblePointers
              : []
            ).map(visiblePointer => {
              return (
                isPointerInBounds(visiblePointer) && (
                  <Pointer
                    key={visiblePointer.codPdv}
                    lat={visiblePointer.lat}
                    lng={visiblePointer.lng}
                    visiblePointer={visiblePointer}
                    zoom={zoom}
                    bounds={bounds}
                  />
                )
              )
            })}
          </GoogleMapReact>
        </div>
      </div>
    </div>
  )
}
