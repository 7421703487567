import React, { useEffect, useRef, useState } from "react"
import styles from "../../styles/hayPromoHayMundial/pointer.module.css"
import iconQuilmes from "../../images/hayPromoHayMundial/pointer.svg"
import clearIcon from "../../images/hayPromoHayMundial/clear.svg"

export default function Pointer({ visiblePointer, zoom }) {
  const [visiblePdvInfo, setVisiblePdvInfo] = useState(false)
  const [width, setWidth] = useState(0);
  const pointerRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pointerRef.current && !pointerRef.current.contains(event.target)) {
        setVisiblePdvInfo(false)
      }
    };
    document.addEventListener("click", handleClickOutside);


    return () => {
      document.addEventListener("click", handleClickOutside);
    };
  }, [pointerRef])

  useEffect(() => {
    setTimeout(() => {
      if (zoom < 11) {
        setWidth(60);
      } else {
        setWidth(40);
      }
    }, 100);
  }, [zoom])

  const handleClick = () => {
    setVisiblePdvInfo(!visiblePdvInfo)
    if(visiblePointer.localidad && visiblePointer.domicilio) {
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Mapa",
        event_action: visiblePointer.provincia,
        event_label: visiblePointer.localidad,
        interaction: "True",
        component_name: visiblePointer.domicilio,
      });
    }
  }

  const handleClear = () => {
    setVisiblePdvInfo(!visiblePdvInfo)
  }


  return (
      <div ref={pointerRef} className={styles.iconContainer} style={{
        width,
      }} onClick={handleClick}>
        <img className={styles.iconImg} src={iconQuilmes} alt="iconQuilmes" />
        {visiblePdvInfo && visiblePointer.localidad && visiblePointer.domicilio && (
          <div className={styles.infoContainer}>
            <div className={styles.topInfo}>
              <h1>{visiblePointer.localidad}</h1>
              <img
                className={styles.clearIcon}
                src={clearIcon}
                onClick={handleClear}
              />
            </div>
            <p>{visiblePointer.domicilio}</p>
          </div>
        )}
      </div>
  )
}
